.context-menu {
    display: none;
    position: absolute;
    background: #fff;
    border: 0.0625em solid darkgray;
    box-shadow: 0.625em 0.625em 1.25em 0.0625em rgba(0, 0, 0, 0.75);
    border-top: none;
}

.context-menu-item {
    padding: 5px 10px;
    cursor: pointer;
    position: relative;
    padding-right: 20px;
}

.context-menu-item .context-menu {
    left: 100%;
    top: 0;
    white-space: nowrap;
}

.context-menu-item:hover {
    background: #eee;
}

.context-menu-item.has-children::after {
    content: '▶'; /* Arrow icon */
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.75em;
}